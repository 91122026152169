import { footerContainer, footerLink, linksContainer } from './footer.css.ts';

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={footerContainer}>
      <div>Synoptic © {currentYear}</div>
      <div className={linksContainer}>
        <a
          href={`mailto:info@synoptic.com`}
          target={'_blank'}
          className={footerLink}
          rel="noreferrer"
        >
          Contact us
        </a>
        <a
          href={'https://x.com/SynopticCom'}
          target={'_blank'}
          className={footerLink}
          rel="noreferrer"
        >
          Twitter
        </a>
      </div>
    </div>
  );
};
