import { AWS_JOIN_WAITLIST_LAMBDA_URL } from '../../env.ts';

type WaitlistEventBodyType = {
  email: string;
  extraData?: {
    category: string;
    description: string;
  };
};

export const joinToWaitlist = async (waitlistData: WaitlistEventBodyType) => {
  const res = await fetch(AWS_JOIN_WAITLIST_LAMBDA_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...waitlistData }),
  });
  if (!res.ok) {
    throw new Error('Failed to submit email');
  }

  return res;
};
